<template>
    <v-content>
        <v-breadcrumbs :items="breadCrumbs" large>
            <template v-slot:item="{ item }">
                <v-breadcrumbs-item :to="item.to"
                                    :disabled="item.disabled"
                                    :exact="item.exact">
                    {{ $t(item.text) }}
                </v-breadcrumbs-item>
            </template>
        </v-breadcrumbs>

        <v-snackbar v-model="snackbar"
                    color="success"
                    :timeout="callbackResponse.timeout"
                    top>
            {{ callbackResponse.message }}
            <v-btn dark text @click="snackbar = false">Close</v-btn>
        </v-snackbar>
        <div class="d-flex justify-space-between">
            <h1 v-if="!isNepaliLanguage"
                class="headline mb-2 mt-5 primary--text">
                {{ branchInformation.branchName + " " + $t("information") }}
            </h1>
            <h1 v-else class="headline mb-2 mt-5 primary--text">
                {{ branchInformation.branchNameNepali + " " + $t("information")  }}
            </h1>
            <v-btn class="mx-2"
                   dark
                   small
                   @click="isEdit=!isEdit"
                   color="cyan">
                <v-icon v-if="!isEdit">
                    mdi-pencil
                </v-icon>
                <span v-if="!isEdit"> {{$t('edit')}}</span>
                <span v-if="isEdit"> {{$t('back')}}</span>
            </v-btn>
        </div>



        <!--<h1 class="font-weight-light">
            {{ branchInformation.branchName + " " + $t("information") }}
        </h1>-->
        <div class="row" v-if="isEdit">
            <div class="col-md-4">
                <v-card class="mx-auto" outlined>
                    <v-list-item three-line>
                        <v-list-item-content>
                            <div class="overline mb-4">{{ $t("profile") }}</div>


                            <v-list-item-title v-if="!isNepaliLanguage"
                                               class="headline mb-1">
                                {{ branchInformation.branchName }}
                            </v-list-item-title>
                            <v-list-item-title v-else class="headline mb-1">
                                {{ branchInformation.branchName }}
                            </v-list-item-title>

                        </v-list-item-content>
                    </v-list-item>
                    <v-img :src="branchInformation.logo" height="194"></v-img>
                    <v-file-input accept="image/png, image/jpeg, image/bmp"
                                  placeholder="Logo"
                                  prepend-icon=""
                                  prepend-inner-icon="mdi-camera"
                                  class="pl-2"
                                  outlined
                                  dense
                                  v-model="branchInformation.updateLogo">
                        <template v-slot:label>
                            {{ $t("avatar") }}
                        </template>
                    </v-file-input>
                    <v-textarea class="mx-2"
                                rows="3"
                                v-model="branchInformation.aboutUs"
                                prepend-inner-icon="comment"
                                outlined
                                dense
                                value="Kapan-3, Kathmandu, Nepal Kapan-3, Kathmandu, Nepal Kapan-3, Kathmandu, Nepal"
                                hint="Hint text">
                        <template v-slot:label>
                            {{ $t("about_us") }}
                        </template>
                    </v-textarea>
                </v-card>
                <v-expansion-panels>
                    <v-expansion-panel>
                        <v-expansion-panel-header color=" dark-blue">
                            {{
              $t("documents")
                            }}
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-card class="py-5" text>
                                <v-row>
                                    <v-col cols="4">
                                        <v-dialog v-model="dialog" width="500">
                                            <template v-slot:activator="{ on }">
                                                <v-img class="pb-2"
                                                       :src="branchInformation.registerDocument"
                                                       height="50"></v-img>
                                                <v-file-input accept="image/png, image/jpeg, image/bmp,image/jpg"
                                                              placeholder="Photo"
                                                              prepend-icon=""
                                                              prepend-inner-icon="mdi-camera"
                                                              class="pl-2"
                                                              v-model="branchInformation.registeredDocument">
                                                    <template v-slot:label>
                                                        {{ $t("register_document") }}
                                                    </template>
                                                </v-file-input>
                                            </template>

                                            <v-card>
                                                <v-card-title class="headline grey lighten-2"
                                                              primary-title>
                                                    {{ $t("register_document") }}
                                                </v-card-title>

                                                <v-card-text>
                                                    <v-img class="pb-2"
                                                           :src="branchInformation.registerDocument"
                                                           aspect-ratio="1"></v-img>
                                                </v-card-text>

                                                <v-divider></v-divider>
                                            </v-card>
                                        </v-dialog>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-dialog v-model="dialog" width="500">
                                            <template v-slot:activator="{ on }">
                                                <v-img class="pb-2"
                                                       :src="branchInformation.vatDocument"
                                                       height="50"></v-img>
                                                <v-file-input accept="image/png, image/jpeg, image/bmp,image/jpg"
                                                              placeholder="Photo"
                                                              prepend-icon=""
                                                              prepend-inner-icon="mdi-camera"
                                                              class="pl-2"
                                                              v-model="branchInformation.newvatDocument">
                                                    <template v-slot:label>
                                                        {{ $t("vat_document") }}
                                                    </template>
                                                </v-file-input>
                                            </template>
                                            <v-card>
                                                <v-card-title class="headline grey lighten-2"
                                                              primary-title>
                                                    {{ $t("vat_document") }}
                                                </v-card-title>

                                                <v-card-text>
                                                    <v-img class="pb-2"
                                                           :src="branchInformation.vatDocument"
                                                           aspect-ratio="1"></v-img>
                                                </v-card-text>

                                                <v-divider></v-divider>
                                            </v-card>
                                        </v-dialog>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-dialog v-model="dialog" width="500">
                                            <template v-slot:activator="{ on }">
                                                <v-img class="pb-2"
                                                       :src="branchInformation.taxClearanceDocument"
                                                       height="50"></v-img>
                                                <v-file-input accept="image/png, image/jpeg, image/bmp,image/jpg"
                                                              placeholder="Photo"
                                                              prepend-icon=""
                                                              prepend-inner-icon="mdi-camera"
                                                              class="pl-2"
                                                              v-model="branchInformation.taxDocument">
                                                    <template v-slot:label>
                                                        {{ $t("tax_clearance_document") }}
                                                    </template>
                                                </v-file-input>
                                            </template>

                                            <v-card>
                                                <v-card-title class="headline grey lighten-2"
                                                              primary-title>
                                                    {{ $t("tax_clearance_document") }}
                                                </v-card-title>

                                                <v-card-text>
                                                    <v-img class="pb-2"
                                                           :src="branchInformation.taxClearanceDocument"
                                                           aspect-ratio="1"></v-img>
                                                </v-card-text>

                                                <v-divider></v-divider>
                                            </v-card>
                                        </v-dialog>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel v-show="branchInformation.divisionName === 'HomeStay'">
                        <v-expansion-panel-header color=" dark-blue">
                            {{ $t("special_dish") }}
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-list flat>
                                <v-list-item-group color="primary">
                                    <v-list-item v-for="(item, i) in dishRows"
                                                 :key="i">
                                        <v-list-item-content>
                                            <v-list-item-title v-text="item.dishName"></v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list-item-group>
                            </v-list>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </div>

            <div class="col-md-8">
                <v-card class="relative" flat>
                    <!-- <v-card-title class="absolute">
                                  <span class="title font-weight-light">Edit Informations</span>
                    </v-card-title>-->
                    <form class="px-2 fix-for-nepali-label">
                        <v-input disabled>{{ branchTypeC }}</v-input>
                        <!-- <v-input disabled>{{branchInformation.branchType}}</v-input> -->
                        <v-row no-gutters>

                            <v-col cols="6" class="px-4">
                                <v-text-field v-model="branchInformation.branchName"
                                              :error-messages="branchNameErrors"
                                              required
                                              outlined
                                              dense
                                              @input="$v.branchInformation.branchName.$touch()"
                                              @blur="$v.branchInformation.branchName.$touch()"
                                              class="col-12">
                                    <template v-slot:label>
                                        {{ $t("brand_s") + " " + $t("name") }}
                                    </template>
                                </v-text-field>
                            </v-col>
                            <v-col cols="6" class="px-4">
                                <v-text-field v-model="branchInformation.branchNameNepali"
                                              :error-messages="branchNameNepaliErrors"
                                              required
                                              outlined
                                              dense
                                              @input="$v.branchInformation.branchNameNepali.$touch()"
                                              @blur="$v.branchInformation.branchNameNepali.$touch()"
                                              class="col-12">
                                    <template v-slot:label>
                                        {{ $t("brand_s") + " " + $t("name")+" "+ $t("in_nepali") }}
                                    </template>
                                </v-text-field>
                            </v-col>

                            <v-col cols="6" class="px-4">

                                <v-text-field v-model="branchInformation.branchEmail"
                                              required
                                              outlined
                                              dense
                                              class="col-12">
                                    <template v-slot:label>
                                        {{ $t("brand_s") + " " + $t("email") }}
                                    </template>
                                </v-text-field>
                            </v-col>
                            <v-col cols="6" class="px-4">
                                <v-text-field v-model="branchInformation.branchPhoneNumber"
                                              :error-messages="branchPhoneNumberErrors"
                                              required
                                              outlined
                                              dense
                                              @input="$v.branchInformation.branchPhoneNumber.$touch()"
                                              @blur="$v.branchInformation.branchPhoneNumber.$touch()"
                                              class="col-12">
                                    <template v-slot:label>
                                        {{ $t("brand_s") + " " + $t("contact_no") }}
                                    </template>
                                </v-text-field>
                            </v-col>
                            <v-col cols="6" class="px-4">

                                <v-text-field v-model="branchInformation.branchAddress"
                                              :error-messages="branchAddressErrors"
                                              required
                                              outlined
                                              dense
                                              @input="$v.branchInformation.branchAddress.$touch()"
                                              @blur="$v.branchInformation.branchAddress.$touch()"
                                              class="col-12">
                                    <template v-slot:label>
                                        {{ $t("brand_s") + " " + $t("address") }}
                                    </template>
                                </v-text-field>
                            </v-col>
                            <v-col cols="6" class="px-4">
                                <v-text-field v-model="branchInformation.branchAddressNepali"
                                              :error-messages="branchAddressNepaliErrors"
                                              required
                                              outlined
                                              dense
                                              @input="$v.branchInformation.branchAddressNepali.$touch()"
                                              @blur="$v.branchInformation.branchAddressNepali.$touch()"
                                              class="col-12">
                                    <template v-slot:label>
                                        {{ $t("brand_s") + " " + $t("address")+ " " + $t("in_nepali")}}
                                    </template>
                                </v-text-field>
                            </v-col>
                            <v-col cols="6" class="px-4">
                                <v-text-field v-show="branchInformation.divisionName === 'HomeStay'"
                                              v-model="branchInformation.focalPerson"
                                              required
                                              outlined
                                              dense
                                              class="col-12">
                                    <template v-slot:label>
                                        {{ $t("brand_s") + " " + $t("focal_person") }}
                                    </template>
                                </v-text-field>
                            </v-col>
                            <v-col cols="6" class="px-4">
                                <v-text-field v-show="branchInformation.divisionName === 'HomeStay'"
                                              v-model="branchInformation.totalHouses"
                                              required
                                              outlined
                                              dense
                                              class="col-12">
                                    <template v-slot:label>
                                        {{ $t("no_of_houses") }}
                                    </template>
                                </v-text-field>
                            </v-col>
                            <v-col cols="6" class="px-4" v-show="branchInformation.divisionName === 'HomeStay'">
                                <v-text-field v-model="branchInformation.totalRooms"
                                              required
                                              outlined
                                              dense
                                              class="col-12">
                                    <template v-slot:label>
                                        {{ $t("pax") }}
                                    </template>
                                </v-text-field>
                            </v-col>
                            <v-col cols="6" class="px-4">
                                <v-text-field v-model="branchInformation.branchWebsite"
                                              required
                                              outlined
                                              dense
                                              class="col-12">
                                    <template v-slot:label>
                                        {{ $t("brand_s") + " " + $t("website") }}
                                    </template>
                                </v-text-field>
                            </v-col>
                            <v-col cols="6" class="px-4">
                                <v-text-field v-model="branchInformation.latitude"
                                              required
                                              outlined
                                              dense
                                              class="col-12">
                                    <template v-slot:label>
                                        {{ $t("latitude") }}
                                    </template>
                                </v-text-field>
                            </v-col>
                            <v-col cols="6" class="px-4">
                                <v-text-field v-model="branchInformation.longitude"
                                              required
                                              outlined
                                              dense
                                              class="col-12">
                                    <template v-slot:label>
                                        {{ $t("longitude") }}
                                    </template>
                                </v-text-field>
                            </v-col>








                            <!--<v-text-field v-model="dish.dishName"
                                          required
                                          class="col-12">
                                <template v-slot:label>
                                    {{ $t("dish_name") }}
                                </template>
                            </v-text-field>-->


                        </v-row>
                    </form>
                </v-card>
            </div>
            <div class="col-12">

                <v-btn dark
                       text
                       depressed
                       class="mr-4 blue darken-1"
                       @click="submit">{{ $t("update") }}</v-btn>
            </div>
            <!--<v-container>

                <v-row>
                    <v-col cols="12" md="6">
                        <v-card>
                            <v-list style="background-color: #077dfa">
                                <v-list-item>
                                    <v-list-item-content align="center">
                                        <v-list-item-title id="table" class="white--text"><strong> S No.</strong> </v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-content align="center">
                                        <v-list-item-title id="table" class=" white--text"><strong>House Name</strong></v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-content align="center">
                                        <v-list-item-title id="table" class=" white--text"><strong>House Owner Name</strong></v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-content align="center">
                                        <v-list-item-title id="table" class=" white--text"><strong>House No.</strong></v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-content align="center">
                                        <v-list-item-title id="table" class=" white--text"><strong>Total Capacity</strong></v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-content align="right">
                                        <v-list-item-title id="table" class="white--text"><strong>Image</strong></v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-card>
                        <v-card style="overflow-y: scroll; height: 350px;">

                            <v-list v-for="(hHouseLists, i) in hotelHouseLists.data"
                                    :key="i">
                                <v-list-item>
                                    <v-list-item-content align="center">
                                        <v-list-item-title id="table" class="black--text">{{hHouseLists.countIndex}} </v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-content align="center">
                                        <v-list-item-title id="table" class="mr-18 black--text"> {{hHouseLists.hotelRoomCategory}}</v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-content align="center">
                                        <v-list-item-title id="table" class="mr-18 black--text"> {{hHouseLists.houseOwner}}</v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-content align="center">
                                        <v-list-item-title id="table" class="mr-18 black--text"> {{hHouseLists.houseNumber}}</v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-content align="center">
                                        <v-list-item-title id="table" class="black--text"> {{hHouseLists.totalRoom}}</v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-content align="right">
                                        <v-list-item-title id="table" class="black--text"> {{hHouseLists.image}}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-divider inset></v-divider>
                            </v-list>
                        </v-card>
                    </v-col>

                </v-row>
            </v-container>-->
        </div>
        <div class="row" v-else>
            <div class="col-4 mt-8">
                <v-img aspect-ratio="1"
                       max-height="300"
                       max-width="281"
                       :src="branchInformation.logo"></v-img>
            </div>
            <div class="col-4">
                <h2>{{$t('table.details')}}</h2>
                <v-divider></v-divider>
                <div>
                    <div class="mt-4">
                        <span class="font-weight-bold mr-8">{{$t('branch_name')}} :</span>{{branchInformation.branchName}}
                    </div>
                    <div class="mt-4">
                        <span class="font-weight-bold mr-8">{{$t('branch_name_nepali')}} :</span>{{branchInformation.branchNameNepali}}
                    </div>
                    <div class="mt-4">
                        <span class="font-weight-bold mr-8">{{$t('branch_email')}} :</span>{{branchInformation.branchEmail}}
                    </div>
                    <div class="mt-4">
                        <span class="font-weight-bold mr-8">{{$t('branch_phonenumber')}} :</span>{{branchInformation.branchPhoneNumber}}
                    </div>
                    <div class="mt-4">
                        <span class="font-weight-bold mr-8">{{$t('branch_address')}} :</span>{{branchInformation.branchAddress}}
                    </div>
                    <div class="mt-4">
                        <span class="font-weight-bold mr-8">{{$t('branch_address_nepali')}} :</span>{{branchInformation.branchAddressNepali}}
                    </div>
                    <div class="mt-4" v-show="branchInformation.divisionName === 'HomeStay'">
                        <span class="font-weight-bold mr-8">{{$t('branch_focal_person')}} :</span>{{branchInformation.focalPerson}}
                    </div>
                    <div class="mt-4" v-show="branchInformation.divisionName === 'HomeStay'">
                        <span class="font-weight-bold mr-8">{{$t('total_rooms')}} :</span>{{branchInformation.totalRooms}}
                    </div>
                    <div class="mt-4">
                        <span class="font-weight-bold mr-8">{{$t('branch_website')}} :</span>{{branchInformation.branchWebsite}}
                    </div>
                    <div class="mt-4">
                        <span class="font-weight-bold mr-8">{{$t('latitude')}} :</span>{{branchInformation.latitude}}
                    </div>
                    <div class="mt-4">
                        <span class="font-weight-bold mr-8">{{$t('longitude')}} :</span>{{branchInformation.longitude}}
                    </div>

                </div>
            </div>
            <div class="col-4">
                <h2>{{$t('table.documents')}}</h2>
                <v-divider></v-divider>
                <div>
                    <div>
                        <span class="font-weight-bold mr-8">{{$t('tax_clearance_document')}} :</span>
                        <v-img class="pb-2"
                               aspect-ratio="1"
                               max-height="300"
                               max-width="281"
                               :src="branchInformation.taxClearanceDocument"></v-img>
                    </div>
                    <div>
                        <span class="font-weight-bold mr-8">{{$t('vat_document')}}  :</span>
                        <v-img class="pb-2"
                               aspect-ratio="1"
                               max-height="300"
                               max-width="281"
                               :src="branchInformation.vatDocument"></v-img>
                    </div>
                    <div>
                        <span class="font-weight-bold mr-8">{{$t('register_document')}}  :</span>
                        <v-img class="pb-2"
                               aspect-ratio="1"
                               max-height="300"
                               max-width="281"
                               :src="branchInformation.registerDocument"></v-img>
                    </div>

                </div>
            </div>
        </div>
    </v-content>
</template>

<script>
    import axios from "axios";
    import { required } from "vuelidate/lib/validators";

    export default {
        name: "BranchDashboard",
        props: ["success"],
        watch: {
            created: {
                deep: true,
            },
        },
        validations: {
            branchInformation: {
                branchName: { required },
                branchNameNepali: { required },
                branchAddress: { required },
                branchAddressNepali: { required },
                branchPhoneNumber: { required },
            },
        },
        computed: {
            isNepaliLanguage() {
                return this.$i18n.locale === "ne";
            },
            branchTypeC() {
                return this.branchInformation.branchType != "undefined"
                    ? this.branchInformation.branchType
                    : "";
            },
            branchNameErrors() {
                const errors = [];
                if (!this.$v.branchInformation.branchName.$dirty) return errors;
                !this.$v.branchInformation.branchName.required &&
                    errors.push(
                        `${this.$t("brand_s")} ${this.$t("name")} ${this.$t("is_required")}`
                    );
                return errors;
            },
            branchNameNepaliErrors() {
                const errors = [];
                if (!this.$v.branchInformation.branchNameNepali.$dirty) return errors;
                !this.$v.branchInformation.branchNameNepali.required &&
                    errors.push(
                        `${this.$t("brand_s")} ${this.$t("name")} ${this.$t("is_required")}`
                    );
                return errors;
            },
            branchAddressErrors() {
                const errors = [];
                if (!this.$v.branchInformation.branchAddress.$dirty) return errors;
                !this.$v.branchInformation.branchAddress.required &&
                    errors.push(
                        `${this.$t("brand_s")} ${this.$t("address")} ${this.$t(
                            "is_required"
                        )}`
                    );
                return errors;
            },
            branchAddressNepaliErrors() {
                const errors = [];
                if (!this.$v.branchInformation.branchAddressNepali.$dirty) return errors;
                !this.$v.branchInformation.branchAddressNepali.required &&
                    errors.push(
                        `${this.$t("brand_s")} ${this.$t("address")} ${this.$t(
                            "is_required"
                        )}`
                    );
                return errors;
            },
            branchPhoneNumberErrors() {
                const errors = [];
                if (!this.$v.branchInformation.branchPhoneNumber.$dirty) return errors;
                !this.$v.branchInformation.branchPhoneNumber.required &&
                    errors.push(
                        `${this.$t("brand_s")} ${this.$t("contact_no")} ${this.$t(
                            "is_required"
                        )}`
                    );
                return errors;
            },
        },
        mounted() {
            this.getDishList();

            this.formData();
            this.breadCrumb();
        },

        data() {
            return {
                callbackResponse: {
                    timeout: 6000,
                },
                snackbar: false,
                dialog: false,
                breadCrumbs: [],
                hotelHouseLists: {},
                //dish: {},
                isEdit: false,
                branchInformation: {},
                formPostData: {},
                dishRows: []

            };
        },
        created() {
            this.getHotelHouseLists();
        },
        methods: {
            breadCrumb() {
                this.breadCrumbs = [
                    {
                        text: "dashboard",
                        disabled: false,
                        to: `${this.userData.dashBoardBranchUser}`,
                        exact: true,
                    },
                    {
                        text: "profile",
                        disabled: true,
                    },
                ];
            },
            getDishList() {
                let param = {
                    PageSize: 50,
                    PageNo: 1,
                    OrderType: null,
                    OrderBy: null
                };
                axios.post("Hotel/GetHomestaySpecialDishList", param)
                    .then(response => {
                        this.dishRows = response.data.data;

                    })
                    .catch(err => {
                        console.log(err)
                    });
            },

            async formData() {
                console.log(`a:${this.userData.dashBoardBranchUser}`);
                const branchInformation = await axios.get(
                    "Branch/GetBranchInformationByBranchID"
                );
                this.branchInformation = branchInformation.data;




                const hotelHouseLists = await axios.get("Hotel/GetHomestayHouseAsync");
                this.hotelHouseLists = hotelHouseLists.data;

                /* Tshering */
                const dish = await axios.post("Hotel/GetHomestaySpecialDishList");
                console.log(dish)

                /* */
            },
            submit() {
                var form = new FormData();
                form.append("Logo", this.branchInformation.updateLogo);
                form.append("BranchName", this.branchInformation.branchName);
                form.append("BranchNameNepali", this.branchInformation.branchNameNepali);
                form.append("BranchAddress", this.branchInformation.branchAddress);
                form.append("BranchAddressNepali", this.branchInformation.branchAddressNepali);
                form.append(
                    "BranchPhoneNumber",
                    this.branchInformation.branchPhoneNumber
                );
                form.append("BranchWebsite", this.branchInformation.branchWebsite);

                /** New CHanges **/

                form.append("TotalRooms", this.branchInformation.totalRooms);
                form.append("TotalMaleStaffs", this.branchInformation.totalMaleStaffs);
                form.append(
                    "TotalFemaleStaffs",
                    this.branchInformation.totalFemaleStaffs
                );
                form.append("TotalHouses", this.branchInformation.totalHouses);
                form.append("FocalPerson", this.branchInformation.focalPerson);
                form.append(
                    "RegisterDocument",
                    this.branchInformation.registeredDocument
                );
                form.append("VatDocument", this.branchInformation.newvatDocument);
                form.append("TaxClearanceDocument", this.branchInformation.taxDocument);
                /* */

                form.append("BranchEmail", this.branchInformation.branchEmail);
                form.append("AboutUs", this.branchInformation.aboutUs);
                form.append("Latitude", this.branchInformation.latitude);
                form.append("Longitude", this.branchInformation.longitude);
                axios
                    .post("BranchProfile/UpdateBranchProfile", form)
                    .then(async (response) => {
                        console.log(response);
                        if (response.data.success == true) {
                            const branchInformation = await axios.get(
                                "Branch/GetBranchInformationByBranchID"
                            );
                            this.branchInformation = branchInformation.data;
                        }
                        this.snackbar = true;
                        this.callbackResponse.message = "Profile updated";
                    })
                    .catch((response) => {
                        console.log(response);
                    });
            },
        },
    };
</script>
